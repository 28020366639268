import '../App/App.css';
//import { useAutoAnimate } from "@formkit/auto-animate/react";
import 'react-clock/dist/Clock.css';
import './SingleOrgStop.css';
import Timetable from '../Timetable/Timetable';
import {
    useParams,
    useSearchParams
} from "react-router-dom"
import OrgInfo from '../stopInfo_multischool.json';
import PrintDate from '../printDate';
import Error404 from '../404/404'
import Container from 'react-bootstrap/Container';

function SingleOrgStop(props) {

    let { orgName,stopName } = useParams();
    const [searchParams] = useSearchParams();
    let data = props.data;
    let lastUpdated = props.lastUpdated;

    const debug = searchParams.get('debug');
    const mode=searchParams.get('mode');

    //const [parent] = useAutoAnimate({ duration: 500 });
    const orgInfo = OrgInfo.orgs.find(x=>x.urlName===orgName);
    const stopInfo = orgInfo.stops.find(x => x.name === stopName);
    if(stopInfo==null){
        return <Error404></Error404>
    }
       
    const timetables = stopInfo.directions.map((stopDirection) =>
        <table className="ttbable" key={'table' + stopDirection.number + lastUpdated}  >
            <tbody>
                <Timetable stopName={stopName} forwardRef={window.parent} class="timetable" key={'timetablec' + stopDirection.number + lastUpdated} lastUpdated={lastUpdated} stopName={stopName} title={stopDirection.title} orgInfo={orgInfo} direction={stopDirection.number} busesToReport={stopDirection.busesToReport} departures={data.stops.find(x => x.stopName === stopName)}></Timetable>
            </tbody>
        </table>);

    return ( 
    <Container fluid>
        <div className={mode=='light'?"bg-light text-dark":"bg-dark text-light"} id="singleStoptrafficViewer">
            {/*<img className="logoImg" src="/img/edgymnasiet.png"/>*/}
            <header id="singleHeader">
              <h1 className={"display-4 "+"text-center "+(mode=='light'?"text-dark bg-light":"bg-dark text-light")}>{orgInfo.displayName} Bussavgångar: <span id="stopNameTitle">{stopName}</span></h1>
            </header>
            <div id="singleStopTimetableContainer">
                {timetables}
            </div>
           {debug=="true"? <p id="lastUpdateText">Client last update:{lastUpdated} Server last update:{PrintDate(data.stops[0].updated)}</p>:<></>}
        </div >
    </Container>
    )
}

export default SingleOrgStop;
