import './Error.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBus } from '@fortawesome/free-solid-svg-icons';

function Error(props) {

    return (
        <div id="errorContainer">

            <h1>OOPS..</h1>
            <h2>Det går inte att visa bussavgångarna  just nu.</h2>

            <p>Här får ni en bild på en buss istället.</p>
            <FontAwesomeIcon id="busIcon" icon={faBus} />
           {/*<p>(the reason is: {props.error.message})</p>*/}
        </div>
    )
}

export default Error;