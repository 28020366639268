import './Timetable.css';
import StopInfoElements from '../StopInfoElements/StopInfoElements';
import {
  useParams,
  useSearchParams
} from "react-router-dom"
import {useState} from "react"

function Timetable(props) {

  const[noBusses,setNoBusses]=useState(1);
  const [searchParams] = useSearchParams();
  const mode=searchParams.get('mode');

  //Prune departures - in case there are buses that have already gone...
  let deps = props.departures.stopInfo.Departure;
  let orgInfo = props.orgInfo

  if (deps != null) {
    let itemsToRemove = [];
    for (let i = 0; i < deps.length; i++) {

      let d = new Date(); // Creates a Date Object using the clients current time
      let [hours, minutes, seconds] = deps[i].time.split(':');
      let [year, month, date] = deps[i].date.split('-');
      d.setFullYear(year, month - 1, date);
      d.setHours(+hours); // Set the hours, using implicit type coercion
      d.setMinutes(minutes); // can pass Number or String - doesn't really matter
      d.setSeconds(seconds);

      if (Date.now() > d) {
        itemsToRemove.push(i);
      }
    }

    for (let j = itemsToRemove.length - 1; j >= 0; j--) {
      let index = itemsToRemove[j];
      deps.splice(index, 1);
    }
  }

  
  return <>
    <tr className={mode=='light'?"bg-light text-dark":"bg-dark text-light"}>
      <td className="directionText" colSpan='3'>{props.title}</td>
    </tr>

{noBusses==0?(
 <StopInfoElements stopName={props.stopName} setNoBusses={setNoBusses} orgInfo={orgInfo} className="timetable" key={'siea' + props.lastUpdated} time={props.time} lastUpdated={props.lastUpdated} busesToReport={props.busesToReport} departures={deps} direction={props.direction}></StopInfoElements>
):(<>
  <tr className={"tableTitleRow "+(mode=='light'?"bg-light text-dark":"bg-dark text-light")} >
  <th>Linje</th>
  <th>Destination</th>
  <th>Avgår</th>
</tr>
<StopInfoElements stopName={props.stopName} setNoBusses={setNoBusses} orgInfo={orgInfo} className="timetable" key={'siea' + props.lastUpdated} time={props.time} lastUpdated={props.lastUpdated} busesToReport={props.busesToReport} departures={deps} direction={props.direction}></StopInfoElements>
</>
)


}

  </>;
}

export default Timetable;
