import './App.css';
//import { useAutoAnimate } from "@formkit/auto-animate/react";
import config from '../config';
import Loading from '../Loading/Loading';
import Error from '../Error/Error';
import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'react-clock/dist/Clock.css';
import LandingPage from '../LandingPage/LandingPage';
import SingleStop from '../SingleStop/SingleStop';
import SingleOrgStop from '../SingleOrgStop/SingleOrgStop';
import Org from '../Org/Org';
import { useSelector, useDispatch } from 'react-redux'
import { set } from '../features/timetable/timetableSlice'

function App() {

  // eslint-disable-next-line
  const [value, setValue] = useState(new Date());
  //const [parent] = useAutoAnimate({ duration: 500 });
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);

    return () => { clearInterval(interval); };
  }, []);

  const printDate = (unixTimeStamp) => {
    var date = new Date(unixTimeStamp);
    return date.toLocaleTimeString("default");
  };

  const [lastUpdated, setLastUpdated] = useState();

  const { isLoading, error, data } = useQuery({ 
    queryKey: ["repoData"],
    queryFn: () =>
    fetch(config.API_URL).then(res => {
      var today = new Date();
      setLastUpdated(printDate(today));
      return res.json();
    }),
    refetchInterval: config.updateTime,
  })
    
  if (isLoading) return <Loading></Loading>;

  if (error) return <Error error={error}></Error>

  //dispatch(set(data))
  return (

    <BrowserRouter>
      <Routes>
        <Route path='*' element={<Error/>} />
        <Route path="/" element={<LandingPage key={lastUpdated} data={data} lastUpdated={lastUpdated} />} />
       {/*Legacy Route*/}
       <Route path="/stop/:stopName" key={lastUpdated} element={<SingleStop data={data} lastUpdated={lastUpdated} />} />
       {/*Legacy Route*/}
       <Route path="/stopid/:stopid" key={lastUpdated} element={<SingleStop data={data} lastUpdated={lastUpdated} />} />
       <Route path="/org/:orgName" key={lastUpdated} element={<Org data={data} lastUpdated={lastUpdated} />} />
       <Route path="/org/:orgName/stop/:stopName" key={lastUpdated} element={<SingleOrgStop data={data} lastUpdated={lastUpdated} />} />
       <Route path="/org/:orgName/stopid/:stopid" key={lastUpdated} element={<SingleOrgStop data={data} lastUpdated={lastUpdated} />} />
      </Routes>
    </BrowserRouter>

  )
}

export default App;
