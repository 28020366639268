import config from '../config';
import './StopInfoElements.css';
import {
  useParams,
  useSearchParams
} from "react-router-dom"

function StopInfoElements(props) {

    let orgInfo=props.orgInfo;
    let stopName=props.stopName;
    
    const [searchParams] = useSearchParams();
  const mode=searchParams.get('mode');
  const timeFlag=searchParams.get('time');
  
  //Deal with case that no departures are available.
  if (props.departures == null || props.departures.length === 0) {
    props.setNoBusses(0);
    return <tr className={"directionText "+(mode=='light'?"text-dark bg-light":"bg-dark text-light")}>
      <td colSpan='3' className={"directionText "+(mode=='light'?"text-dark bg-light":"bg-dark text-light")}>Inga bussar just nu.</td>
    </tr>;
  }

  let departures = props.departures.filter(x => x.directionFlag == props.direction);
  let newdepartures = departures.filter(item => props.busesToReport.includes(item.Product[0].num));
  if (newdepartures.length > config.numberItemsInList) {
    newdepartures = newdepartures.slice(0, config.numberItemsInList);
  }

  function createBusTime(time, currentTime) {
      //console.log(currentTime)

        
    if(timeFlag=="1"){
      let d = new Date(); // Creates a Date Object using the clients current time
      let e = new Date()
      let [hours, minutes, seconds] = time.split(':');
   
      
       d.setHours(+hours); // Set the hours, using implicit type coercion
       d.setMinutes(minutes); // can pass Number or String - doesn't really matter
       d.setSeconds(seconds);

       const diffTimeMilliseconds = Math.abs(d - e);
       const diffTimeMinutes = Math.floor(diffTimeMilliseconds/(1000*60))
       if (diffTimeMinutes==0) return "Nu"
       else return (diffTimeMinutes +" min")
    }
     
      

    return time.slice(0, 5);
  }

  if (newdepartures.length === 0) {
    return <tr className={"directionText "+(mode=='light'?"text-dark bg-light":"bg-dark text-light")}>
      <td colSpan='3' className={"directionText "+(mode=='light'?"text-dark bg-light":"bg-dark text-light")}>Inga bussar just nu.</td>
    </tr>
  }

  function checkForAlias(number,orig_direction){
 
      let stop=orgInfo.stops.find((x)=>x.name==stopName);
      //console.log(stop);
      if(stop==null) return orig_direction;
      let direction=stop.directions.find((x)=>x.number==props.direction);
      //console.log(direction);
      if(direction==null) return orig_direction;
      let aliases=direction.aliases;
      if (aliases==null) return orig_direction;
      //console.log(aliases);
      let alias=aliases.find((x)=>x.number==number)
      //console.log(alias)
      if(alias!=null){
        return alias.alias;
      }
      else return orig_direction 
  }

  return newdepartures.map((departure, index) =>
    <tr key={index + props.lastUpdated} className={(mode=='light'?"bg-light text-dark":"bg-dark text-light")}>
      <td className={"busNumber tableRow "+" "+"bus"+departure.Product[0].num+" "+"rounded "+ (mode=='light'?"border-light rounded":"border-dark rounded")}>{departure.Product[0].num}</td>
      {/*<td>{departure.direction.replace(' (Jönköping kn)', '').replace('Jönköping ', '')}</td>*/}
      <td className={mode=='light'?"bg-light text-dark":"bg-dark text-light"}>{checkForAlias(departure.Product[0].num,departure.direction.replace(' (Jönköping kn)', '').replace('Jönköping ', ''))}</td>
      <td className={"timeCell "+(mode=='light'?"bg-light text-dark":"bg-dark text-light")}>{createBusTime(departure.time, props.time)}</td>
    </tr>
  )
};
export default StopInfoElements;
