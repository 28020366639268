import '../App/App.css';
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useState } from 'react';
import 'react-clock/dist/Clock.css';
import PrintDate from '../printDate';
import {Navbar, NavbarBrand,Button,Container,ExampleToast} from 'react-bootstrap'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StopInfo from '../stopInfo_multischool.json'
import { Link,useParams } from "react-router-dom";

function Org(props) {

    let { orgName } = useParams();
    let data = props.data;
    let lastUpdated = props.lastUpdated;

    const [value, setValue] = useState(new Date());
    const [parent] = useAutoAnimate({ duration: 500 });

    const org = StopInfo.orgs.find((x)=>x.urlName==orgName)
    const StopsPanels = org.stops.map((stop)=>{
        return(
            <Col className="text-center"><Link to={"/org/"+orgName+"/stop/"+stop.name}>{stop.name}</Link></Col>
        )
    })
    return (
        <div>
           <Navbar className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home">
            Traffic
          </Navbar.Brand>
        </Container>
      </Navbar>
           
            <Container className="p-3">
    <Container className="p-5 mb-4 bg-light rounded-3">
      <h1 className="header text-center">Trafik information i Jönköping</h1>
    </Container>
  </Container>
           <Container>
           <Row>
       {StopsPanels}
      </Row>
           </Container>
            <p id="lastUpdateText">Client last update:{lastUpdated} Server last update:{PrintDate(data.stops[0].updated)}</p>
        </div >
    )
}

export default Org;
