import App from './App/App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import './index.scss'
import store from './store'
import { Provider } from 'react-redux'

const queryClient = new QueryClient();

console.log("ED Traffic\nBy: Andrew Galbraith")

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <App />
     </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);

reportWebVitals();
