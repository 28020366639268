import './404.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBus } from '@fortawesome/free-solid-svg-icons';

function Error404(props) {

    return (
        <div id="errorContainer">

            <h1>404 OOPS..</h1>
            <h2>Denna sidan verkar inte finnas.</h2>

            <p>Här får ni en bild på en buss istället.</p>
            <FontAwesomeIcon id="busIcon" icon={faBus} />
           {/*<p>(the reason is: {props.error.message})</p>*/}
        </div>
    )
}

export default Error404;