var config = {};

/**********API Specific **************/
config.API_URL = "https://traffic.teed.se/api/traffic";
//How often is information requested (ms)
config.updateTime = 10000;
/**************************************/

config.numberItemsInList = 6;
config.displayClock = false;
config.showMins = false;

module.exports = config;