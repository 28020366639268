import '../App/App.css';
//import { useAutoAnimate } from "@formkit/auto-animate/react";
import 'react-clock/dist/Clock.css';
import './SingleStop.css';
import Timetable from '../Timetable/Timetable';
import {
    useParams
} from "react-router-dom"
import {useEffect} from 'react'
import StopInfo from '../stopInfo_proper.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBus } from '@fortawesome/free-solid-svg-icons';
import PrintDate from '../printDate';
import Error404 from '../404/404'
import { redirect,useNavigate } from "react-router-dom";



function SingleStop(props) {

   
    let { stopName } = useParams();
    let data = props.data;
    let lastUpdated = props.lastUpdated;

    console.log(stopName)
    const navString='/org/ed/stop/'+stopName
    console.log(navString)
    const navigate = useNavigate();

    useEffect(() => {
      
        navigate(navString);
      
    });

    return;
    
   // useNavigate("/org/ed/stop/"+stopName) 
    //redirect("/org/ed/stop/"+stopName)
    //const [parent] = useAutoAnimate({ duration: 500 });

    const stopInfo = StopInfo.stops.find(x => x.name === stopName);
    if(stopInfo==null){
        return <Error404></Error404>
    }
       
    

    const timetables = stopInfo.directions.map((stopDirection) =>
        <table class="bg-dark" key={'table' + stopDirection.number + lastUpdated}  >
            <tbody>
                <Timetable forwardRef={window.parent} class="timetable" key={'timetablec' + stopDirection.number + lastUpdated} lastUpdated={lastUpdated} stopName={stopName} title={stopDirection.title} direction={stopDirection.number} busesToReport={stopDirection.busesToReport} departures={data.stops.find(x => x.stopName === stopName)}></Timetable>
            </tbody>
        </table>);

    return (
        <div className='bg-dark text-light' id="singleStoptrafficViewer" >
            <header id="singleHeader">
                <FontAwesomeIcon id="busLogo" icon={faBus} />
                <h1>ED Bussavgångar: <span id="stopNameTitle">{stopName}</span></h1>
            </header>
            <div id="singleStopTimetableContainer">
                {timetables}
            </div>
            <p id="lastUpdateText">Client last update:{lastUpdated} Server last update:{PrintDate(data.stops[0].updated)}</p>
        </div >
    )
}

export default SingleStop;
