import './Loading.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBus } from '@fortawesome/free-solid-svg-icons';
import {
    useParams,
    useSearchParams
} from "react-router-dom"

function Loading(props) {

   
    const mode=props.mode

    return (
        <div id="loadingContainer" className={mode=='light'?"bg-light text-dark":"bg-dark text-light"}>
            <FontAwesomeIcon className={mode=='light'?"bg-light text-dark":"bg-dark text-light"} id="busIcon" icon={faBus} />
            <p id="loadingText" className={mode=='light'?"bg-light text-dark":"bg-dark text-light"}>Loading...</p>
        </div>
    )
}

export default Loading;